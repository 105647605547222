import React from 'react';
import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import {
  PARTNER_ASTRO_PROXY_EN_ROUTE,
  PARTNER_ASTRO_PROXY_RU_ROUTE,
  SIGN_UP_ROUTE,
} from '../../const/routes.const';
import {
  ASTRO_PROXY_DISCOUNT,
  ASTRO_PROXY_NAME,
  ASTRO_PROXY_PROMO_CODE,
} from '../../const/parnters.const';
import PartnerLayout from '../../components/Partners/PartnerLayout';
import astroproxy_desktop from '../../assets/images/partners/astroproxy_desktop.png';
import astroproxy_desktop2x from '../../assets/images/partners/astroproxy_desktop_2x.png';
import astroproxy_mobile from '../../assets/images/partners/astroproxy_mobile.png';
import astroproxy_mobile2x from '../../assets/images/partners/astroproxy_mobile_2x.png';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import BlueSpan from '../../components/common/typography/BlueSpan';
import PartnerDiscount from '../../components/Partners/PartnerDiscount';
import LinkWrapper from '../../components/common/LinkWrapper';
import { LanguageMap } from '../../const/language.const';

const AstroProxyPage = (): React.ReactElement => {
  const { language } = useI18next();

  const astroProxyRoute =
    language === LanguageMap.ru.key ? PARTNER_ASTRO_PROXY_RU_ROUTE : PARTNER_ASTRO_PROXY_EN_ROUTE;

  return (
    <PartnerLayout
      description={
        <>
          <p>
            <Trans>
              AstroProxy is an enterprise infrastructure for collecting, analyzing, and using
              publicly available web data.
            </Trans>
          </p>
          <ul>
            <li>
              <Trans>
                Over a million of proxies geolocated in 100+ countries with top-notch client
                support.
              </Trans>
            </li>
            <li>
              <Trans>
                Innovative single-paged dashboard for instant access to proxies, managing settings,
                and detailed statistics.
              </Trans>
            </li>
            <li>
              <Trans>
                API-compatible and reliable rotating IP addresses, supporting both HTTP(S) and
                SOCKS5.
              </Trans>
            </li>
            <li>
              <Trans>Expert customer support</Trans>
            </li>
          </ul>
          <h3>
            <Trans i18nKey="Offer for Octo Browser users">
              Offer for <BlueSpan>Octo Browser users</BlueSpan>
            </Trans>
          </h3>
          <p>
            <Trans i18nKey="This offer is valid for Octo Browser users only. Create an account to claim your discount. If you already use Octo Browser, go to the partner's website, sign up, and claim your offer.">
              This offer is valid for Octo Browser users only. <LinkWrapper url={SIGN_UP_ROUTE}>
                Create an account</LinkWrapper>{' '}to claim your discount. If you already use Octo Browser, go to the partner's website, sign up, and
              claim your offer.
            </Trans>
          </p>
          <PartnerDiscount
            description={
              <Trans
                i18nKey="partner.astroproxy.promo.code.discount"
                tOptions={{
                  discount: ASTRO_PROXY_DISCOUNT,
                  promoCode: ASTRO_PROXY_PROMO_CODE,
                  name: ASTRO_PROXY_NAME,
                }}
              >
                <strong>{ASTRO_PROXY_PROMO_CODE}</strong>.
              </Trans>
            }
            discount={ASTRO_PROXY_DISCOUNT}
            href={astroProxyRoute}
          />
          <h3>
            <Trans>AstroProxy key solutions and advantages:</Trans>
          </h3>
          <h4>
            <Trans>Mobile proxies</Trans>
          </h4>
          <ul>
            <li>
              <Trans>Real end-user mobile 4G/3G IP addresses</Trans>
            </li>
            <li>
              <Trans>80+ mobile carriers to choose from</Trans>
            </li>
            <li>
              <Trans>High protection against bans or CAPTCHA</Trans>
            </li>
            <li>
              <Trans>Starting at $1.31 / month</Trans>
            </li>
          </ul>
          <h4>
            <Trans>Residential proxies</Trans>
          </h4>
          <ul>
            <li>
              <Trans>Geolocation coverage of 100+ countries</Trans>
            </li>
            <li>
              <Trans>Almost 400 Internet Service Providers available</Trans>
            </li>
            <li>
              <Trans>Starting at $0.73 / month</Trans>
            </li>
            <li>
              <Trans>Country-, city-, and ISP-level targeting</Trans>
            </li>
          </ul>
          <h4>
            <Trans>Datacenter proxies</Trans>
          </h4>
          <ul>
            <li>
              <Trans>Individual proxies from 150+ subnets and datacenters</Trans>
            </li>
            <li>
              <Trans>Starting at $0.37 / month</Trans>
            </li>
            <li>
              <Trans>Fast and stable sessions with adjustable duration</Trans>
            </li>
            <li>
              <Trans>White IP rotation</Trans>
            </li>
          </ul>
          <h4>
            <Trans>AstroProxy key features:</Trans>
          </h4>
          <ul>
            <li>
              <Trans>Dedicated dynamic proxies with IP rotation within selected pools</Trans>
            </li>
            <li>
              <Trans>
                Configurable parameters to rotate IP by timer or connection, for every request,
                using API, manually, etc.
              </Trans>
            </li>
            <li>
              <Trans>Instant access to all features and geo locations without extra fees</Trans>
            </li>
            <li>
              <Trans>
                No limits on number of proxy ports running at once, up to 100 TCP/IP connections for
                every port
              </Trans>
            </li>
            <li>
              <Trans>99.9% uptime</Trans>
            </li>
            <li>
              <Trans>HTTPS and SOCKS5 support for every port</Trans>
            </li>
            <li>
              <Trans>Encryption-ready proxies (OpenVPN encryption presets)</Trans>
            </li>
            <li>
              <Trans>
                Easy-to-integrate, highly customizable and compatible API for automation
              </Trans>
            </li>
            <li>
              <Trans>KYC & AML compliance</Trans>
            </li>
            <li>
              <Trans>Detailed proxy usage statistics for every proxy</Trans>
            </li>
            <li>
              <Trans>Top-notch client support via messengers, email, and social media</Trans>
            </li>
            <li>
              <Trans>Customizable pricing plans</Trans>
            </li>
            <li>
              <Trans>Payments with cryptocurrency, cards, or e-wallets</Trans>
            </li>
            <li>
              <Trans>Access protected by password or whitelisted IPs</Trans>
            </li>
          </ul>
        </>
      }
      href={astroProxyRoute}
      imgDesktop={
        <ImageContainer src={astroproxy_desktop} srcSet={{ _2x: astroproxy_desktop2x }} />
      }
      imgMobile={<ImageContainer src={astroproxy_mobile} srcSet={{ _2x: astroproxy_mobile2x }} />}
      title={ASTRO_PROXY_NAME}
    />
  );
};

export default AstroProxyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["astroproxyPage", "translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
